import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {GlobalConstants } from "../../utils/GlobalConst";
import { useNavigate } from "react-router";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function ResellCheckout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openInfo, setOpenInfo] = useState(false);
  const handleCloseInfo = () => {
    setOpenInfo(false);
  };
  const [text_ph_enterEmailId, setText_ph_enterEmailId] = useState(
    "Enter Your Email ID"
  );
  const [text_paynow, setText_paynow] = useState("Pay now");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_yes, setText_yes] = useState("Yes");
  const [text_no, setText_no] = useState("No");
  const [text_noticecheckout, setText_noticecheckout] = useState(
    "Are you sure you want to cancel transaction?"
  );
  const [text_confirmationemail, setText_confirmationemail] = useState(
    "Confirmation Email ID"
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          let payid = paymentIntent.id;
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/resell-payment",
        return_url: "https://www.tiq8.com/resell-payment",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      toast.error(error.message);
    } else {
      setMessage("An unexpected error occurred.");
      toast.error("An unexpected error occurred");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const handleTestBuy = () => {
    let text = props.clientSecret;
    const myArray = text.split("_secret_");
    console.log(myArray[0]);
    navigate("/resell-payment", {
      state: { paymentid: myArray[0] },
    });
  };

  const SetLanguageText = () => {
      try {
        var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
        var doc = new dom().parseFromString(xml);
        setText_ph_enterEmailId(
          xpath.select1("/resources/string[@name='text_ph_enterEmailId']", doc)
            .firstChild.data
        );
        setText_paynow(
          xpath.select1("/resources/string[@name='text_paynow']", doc).firstChild
            .data
        );
        setText_cancel(
          xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
            .data
        );
        setText_yes(
          xpath.select1("/resources/string[@name='text_yes']", doc).firstChild
            .data
        );
        setText_no(
          xpath.select1("/resources/string[@name='text_no']", doc).firstChild
            .data
        );
        setText_noticecheckout(
          xpath.select1("/resources/string[@name='text_noticecheckout']", doc).firstChild
            .data
        );
        setText_confirmationemail(
          xpath.select1("/resources/string[@name='text_confirmationemail']", doc).firstChild
            .data
        );
      } catch (ert) {}
    };
  return (
    <>
      <center>
        <div className="w-50">
          <div id="payment-form">
            <h4 className="text-start">{text_confirmationemail}</h4>
            <input
              type="email"
              className="form-control h_50 mb-3"
              placeholder={text_ph_enterEmailId}
              id="link-authentication-element"
              defaultValue={sessionStorage.getItem("useremail")}
              onChange={(e) => [setEmail(e.target.value), console.log(e)]}
            />
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button
              disabled={isLoading || !stripe || !elements}
              id="submit"
              className="payNowButton mt-2"
              onClick={handleSubmit}
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  <>{text_paynow}</>
                )}
              </span>
            </button>
            <button
              className="payNowButtonCancel mt-2"
              onClick={() => setOpenInfo(true)}
            >
              <span id="button-text">{text_cancel}</span>
            </button>
          </div>
          <Dialog
            fullScreen={fullScreen}
            open={openInfo}
            onClose={handleCloseInfo}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="text-center"
            ></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <h3 className="text-center">
                  {text_noticecheckout}
                </h3>
                <br />
              </DialogContentText>
              <Divider />
            </DialogContent>
            <>
              <div className="d-flex justify-content-center mb-3 gap-4">
                <button className="cancelBtn " onClick={handleCloseInfo}>
                  {text_no}
                </button>
                <button className="yesBtn ">{text_yes}</button>
              </div>
            </>
          </Dialog>
        </div>
      </center>
    </>
  );
}

export default ResellCheckout;
