import React, { useState, useEffect } from "react";
import "../CreateVenue/CreateVenue.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { Box, Divider, Modal } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { RxCross2 } from "react-icons/rx";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function CreateVenue() {
  const navigate = useNavigate();
  const [Country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [venueCountry, setVenueCountry] = useState("");
  const [venueState, setVenueState] = useState("");
  // const variable array to save the users location
  const [userLocation, setUserLocation] = useState(null);
  const [city, setCity] = useState("");
  const [vanueName, setVanueName] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [layoutUrl, setLayoutUrl] = useState("");
  const [openVenueImageUploadPopup, setOpenVenueImageUploadPopup] =
    useState(false);
  const handleOpenVenueImagePopup = () => setOpenVenueImageUploadPopup(true);
  const handleCloseVenueImagePopup = () => setOpenVenueImageUploadPopup(false);

  //   language variable
  const [text_venueName, setText_venueName] = useState("Venue Name");
  const [text_ph_venueName, setText_ph_venueName] =
    useState("Enter venue name");
  const [text_state, setText_state] = useState("State");
  const [text_ph_state, setText_ph_state] = useState("Select State");
  const [text_venueLayout, setText_venueLayout] = useState("Venue Layout");
  const [text_venueAddress, setText_venueAddress] = useState("Venue Address");
  const [text_ph_venueAddress, setText_ph_venueAddress] = useState(
    "Enter venue address"
  );
  const [text_city, setText_city] = useState("City");
  const [text_ph_city, setText_ph_city] = useState("Select City");
  const [text_country, setText_country] = useState("Country");
  const [text_ph_country, setText_ph_country] = useState("Select Country");
  const [text_zipcode, setText_zipcode] = useState("Zipcode");
  const [text_ph_zipcode, setText_ph_zipcode] = useState("Enter zipcode");
  const [text_seatingDetails_title, setText_seatingDetails_title] = useState(
    "Seating Arrangement Details"
  );
  const [text_add, setText_add] = useState("Add");
  const [text_zone, setText_zone] = useState("Zone");
  const [text_section, setText_section] = useState("Section");
  const [text_subSection, setText_subSection] = useState("Sub Section");
  const [text_ph_enterZone, setText_ph_enterZone] = useState("Enter zone name");
  const [text_ph_enterSection, setText_ph_enterSection] =
    useState("Enter section name");
  const [text_ph_enterSubSection, setText_ph_enterSubSection] = useState(
    "Enter subsection name"
  );
  const [text_section_entryGate, setText_section_entryGate] = useState(
    "Entry Gate for section"
  );
  const [text_ph_section_entryGate, setText_ph_section_entryGate] = useState(
    "Entry gate for section"
  );
  const [text_numberOfSeats, setText_numberOfSeats] =
    useState("Number of Seats");
  const [text_ph_numberOfSeats, setText_ph_numberOfSeats] =
    useState("Enter seats count");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_upload_image, setText_upload_image] = useState("Upload Image");
  const [text_upload, setText_upload] = useState("Upload");
  const [text_price, setText_price] = useState("Price");
  const [text_priceforsection, setText_priceforsection] = useState(
    "Enter Price for Section"
  );
  const [text_columncount, setText_columncount] =
    useState("Enter Column Count");
  const [text_column, setText_column] = useState("Column");
  const [text_rowcount, setText_rowcount] = useState("Enter Row Count");
  const [text_rows, setText_rows] = useState("Rows");
  const [text_createvenue, setText_createvenue] = useState("Create Venue");
  const [text_home, setText_home] = useState("Home");

  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const [inputFields, setInputFields] = useState([
    {
      seating_id: uuidv4(),
      zone: "",
      section: "",
      entrygate: "",
      number_of_seat: "",
      subsection: "",
      rows: "",
      column: "",
      price: "",
    },
  ]);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("InputFields", inputFields);
    VenueCreateHandle();
  };

  const handleChangeInput = (_id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (_id === i.seating_id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setInputFields(newInputFields);
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        seating_id: uuidv4(),
        zone: "",
        section: "",
        entrygate: "",
        number_of_seat: "",
        subsection: "",
        rows: "",
        column: "",
        price: "",
      },
    ]);
  };
  const handleRemoveFields = (seating_id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.seating_id === seating_id),
      1
    );
    setInputFields(values);
  };
  const VenueCreateHandle = () => {
    var API_URL = API_DOMAIN + "venue/addvenue";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      venuename: vanueName,
      address: address,
      country: venueCountry,
      state: venueState,
      city: city,
      zipcode: zipcode,
      layout: layoutUrl,
      seatingarrangement: inputFields,
      location: { type: "Point", coordinates: userLocation },
    };

    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create", response);
        navigate("/create-venue/configuration", {
          state: { venuedetails: response.data.data },
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  const VenueimguplpoadValidation = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setVenueImage(file);
        // You can perform further actions with the selected file here
      } else {
        setVenueImage(null);
        alert("Please upload only JPEG or PNG files.");
      }
    }
  };

  const handleVenueImgUpload = () => {
    var API_URL = API_DOMAIN + "file/upload";
    var imgdata = new FormData();
    imgdata.append("att_file", venueImage);
    let headerConfig = {
      headers: {
        "Content-Type": "form-data",
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .post(API_URL, imgdata, headerConfig)
      .then(function (response) {
        console.log("Venue Img Upload", response.data.data.Location);
        setLayoutUrl(response.data.data.Location);
        setTimeout(() => {
          handleCloseVenueImagePopup();
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_venueName(
        xpath.select1("/resources/string[@name='text_venueName']", doc)
          .firstChild.data
      );
      setText_ph_venueName(
        xpath.select1("/resources/string[@name='text_ph_venueName']", doc)
          .firstChild.data
      );
      setText_state(
        xpath.select1("/resources/string[@name='text_state']", doc).firstChild
          .data
      );
      setText_ph_state(
        xpath.select1("/resources/string[@name='text_ph_state']", doc)
          .firstChild.data
      );
      setText_venueLayout(
        xpath.select1("/resources/string[@name='text_venueLayout']", doc)
          .firstChild.data
      );
      setText_venueAddress(
        xpath.select1("/resources/string[@name='text_venueAddress']", doc)
          .firstChild.data
      );
      setText_ph_venueAddress(
        xpath.select1("/resources/string[@name='text_ph_venueAddress']", doc)
          .firstChild.data
      );
      setText_city(
        xpath.select1("/resources/string[@name='text_city']", doc).firstChild
          .data
      );
      setText_ph_city(
        xpath.select1("/resources/string[@name='text_ph_city']", doc).firstChild
          .data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );
      setText_ph_country(
        xpath.select1("/resources/string[@name='text_ph_country']", doc)
          .firstChild.data
      );
      setText_zipcode(
        xpath.select1("/resources/string[@name='text_zipcode']", doc).firstChild
          .data
      );
      setText_ph_zipcode(
        xpath.select1("/resources/string[@name='text_ph_zipcode']", doc)
          .firstChild.data
      );
      setText_seatingDetails_title(
        xpath.select1(
          "/resources/string[@name='text_seatingDetails_title']",
          doc
        ).firstChild.data
      );
      setText_ph_enterSubSection(
        xpath.select1("/resources/string[@name='text_ph_enterSubSection']", doc)
          .firstChild.data
      );
      setText_add(
        xpath.select1("/resources/string[@name='text_add']", doc).firstChild
          .data
      );
      setText_zone(
        xpath.select1("/resources/string[@name='text_zone']", doc).firstChild
          .data
      );
      setText_section(
        xpath.select1("/resources/string[@name='text_section']", doc).firstChild
          .data
      );
      setText_subSection(
        xpath.select1("/resources/string[@name='text_subSection']", doc)
          .firstChild.data
      );
      setText_ph_enterZone(
        xpath.select1("/resources/string[@name='text_ph_enterZone']", doc)
          .firstChild.data
      );
      setText_ph_enterSection(
        xpath.select1("/resources/string[@name='text_ph_enterSection']", doc)
          .firstChild.data
      );
      setText_section_entryGate(
        xpath.select1("/resources/string[@name='text_section_entryGate']", doc)
          .firstChild.data
      );
      setText_ph_section_entryGate(
        xpath.select1(
          "/resources/string[@name='text_ph_section_entryGate']",
          doc
        ).firstChild.data
      );
      setText_numberOfSeats(
        xpath.select1("/resources/string[@name='text_numberOfSeats']", doc)
          .firstChild.data
      );
      setText_ph_numberOfSeats(
        xpath.select1("/resources/string[@name='text_ph_numberOfSeats']", doc)
          .firstChild.data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_upload_image(
        xpath.select1("/resources/string[@name='text_upload_image']", doc)
          .firstChild.data
      );
      setText_priceforsection(
        xpath.select1("/resources/string[@name='text_priceforsection']", doc)
          .firstChild.data
      );
      setText_price(
        xpath.select1("/resources/string[@name='text_price']", doc).firstChild
          .data
      );
      setText_upload(
        xpath.select1("/resources/string[@name='text_upload']", doc).firstChild
          .data
      );
      setText_columncount(
        xpath.select1("/resources/string[@name='text_columncount']", doc)
          .firstChild.data
      );
      setText_column(
        xpath.select1("/resources/string[@name='text_column']", doc).firstChild
          .data
      );
      setText_rowcount(
        xpath.select1("/resources/string[@name='text_rowcount']", doc)
          .firstChild.data
      );
      setText_rows(
        xpath.select1("/resources/string[@name='text_rows']", doc).firstChild
          .data
      );
      setText_createvenue(
        xpath.select1("/resources/string[@name='text_createvenue']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  // define the function that finds the users geolocation
  const getUserLocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          // update the value of userlocation variable
          setUserLocation([latitude, longitude]);
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>

                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_createvenue}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_createvenue}</h3>
                </div>
              </div>
              <div className="main-card">
                <div className="p-4 bp-form main-form">
                  <div className="row p-4">
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_venueName}
                          <span className="starsty">*</span>
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_venueName}
                          onChange={(e) => setVanueName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_venueAddress}{" "}
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_venueAddress}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_country}{" "}
                        </label>
                        <CountrySelect
                          onChange={(e) => [
                            setCountry(e.id),
                            setVenueCountry(e.name),
                          ]}
                          showFlag={false}
                          placeHolder={text_ph_country}
                          className="form-control h_50"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_state}{" "}
                        </label>
                        <StateSelect
                          countryid={Country}
                          onChange={(e) => [
                            setState(e.id),
                            setVenueState(e.name),
                          ]}
                          placeHolder={text_ph_state}
                          className="form-control h_50"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">{text_city} </label>
                        <CitySelect
                          countryid={Country}
                          stateid={state}
                          onChange={(e) => [
                            console.log(e.name),
                            setCity(e.name),
                          ]}
                          placeHolder={text_ph_city}
                          className="form-control h_50"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_zipcode}{" "}
                        </label>
                        <input
                          className="form-control h_50"
                          type="text"
                          placeholder={text_ph_zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <h1> Get User Location</h1>
                      {/* create a button that is mapped to the function which retrieves the users location */}
                      <button className="main-btn" onClick={getUserLocation}>Fetch</button>
                      {/* if the user location variable has a value, print the users location */}
                      {userLocation && (
                        <div>
                          <h2>User Locationn</h2>
                          <p>Latitude: {userLocation[0].latitude}</p>
                          <p>Longitude: {userLocation[0].longitude}</p>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group border_bottom-create profile-night-form pb_30">
                        <label className="form-label fs-16">
                          {text_venueLayout}{" "}
                        </label>
                        <button
                          onClick={handleOpenVenueImagePopup}
                          className="main-btn"
                        >
                          {text_upload}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h3>{text_seatingDetails_title}</h3>
                    <button onClick={handleAddFields} className="add-btn-venue">
                      {text_add}
                    </button>
                  </div>
                  <hr />
                  <div className="gatezonebox">
                    {inputFields.map((inputField) => {
                      return (
                        <>
                          <div className=" seatingArrengmentBox">
                            <div className=" ">
                              <h5>{text_zone} </h5>
                              <input
                                type="text"
                                value={inputField.zone}
                                name="zone"
                                id=""
                                className="form-control h_40 venue-input"
                                placeholder={text_ph_enterZone}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>
                            <div className=" ">
                              <h5>{text_section} </h5>
                              <input
                                type="text"
                                value={inputField.section}
                                name="section"
                                className="form-control h_40 venue-input"
                                placeholder={text_ph_enterSection}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>
                            <div className=" ">
                              <h5>{text_subSection} </h5>
                              <input
                                type="text"
                                value={inputField.subsection}
                                name="subsection"
                                id=""
                                className="form-control h_40 venue-input "
                                placeholder={text_ph_enterSubSection}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>
                            <div className=" ">
                              <h5>{text_section_entryGate} </h5>
                              <input
                                type="text"
                                value={inputField.entrygate}
                                name="entrygate"
                                id=""
                                className="form-control h_40 venue-input"
                                placeholder={text_ph_section_entryGate}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>

                            <div className=" ">
                              <h5>{text_numberOfSeats} </h5>
                              <input
                                type="text"
                                value={inputField.number_of_seat}
                                name="number_of_seat"
                                id=""
                                className="form-control h_40 venue-input"
                                placeholder={text_ph_numberOfSeats}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>
                            <div className=" ">
                              <h5>{text_rows} </h5>
                              <input
                                type="number"
                                value={inputField.rows}
                                name="rows"
                                id=""
                                className="form-control h_40 venue-input "
                                placeholder={text_rowcount}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>
                            <div className=" ">
                              <h5>{text_column} </h5>
                              <input
                                type="number"
                                value={inputField.column}
                                name="column"
                                id=""
                                className="form-control h_40 venue-input "
                                placeholder={text_columncount}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>
                            <div className=" ">
                              <h5>{text_price} </h5>
                              <input
                                type="number"
                                value={inputField.price}
                                name="price"
                                id=""
                                className="form-control h_40 venue-input "
                                placeholder={text_priceforsection}
                                onChange={(event) =>
                                  handleChangeInput(
                                    inputField.seating_id,
                                    event
                                  )
                                }
                              />
                            </div>
                            <div className=" ">
                              <button
                                onClick={() =>
                                  handleRemoveFields(inputField.seating_id)
                                }
                                className="removeBtnVenue"
                              >
                                <RxCross2 className="crossIcon" />
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-4 mt-5">
                  <button className="cancelBtn">{text_cancel}</button>
                  <button className="saveBtn" onClick={handleSubmit}>
                    {text_save}
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openVenueImageUploadPopup}
        onClose={() => [handleCloseVenueImagePopup()]}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseVenueImagePopup()]}
              />
            </div>
            <h3>{text_upload_image}</h3>
            <Divider />
            <div className="container">
              <div className="text-center">
                <input
                  type="file"
                  className="mt-3 form-control h_50 uploadinput"
                  onChange={VenueimguplpoadValidation}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button
                onClick={() => handleCloseVenueImagePopup()}
                className="cancelBtn"
              >
                {" "}
                {text_cancel}
              </button>

              <button onClick={handleVenueImgUpload} className="saveBtn">
                {" "}
                {text_save}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
      <Footer />
    </>
  );
}

export default CreateVenue;
