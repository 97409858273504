import React, { useState, useEffect } from "react";
import "../Register/Register.css";
import mainlogo from "../../assets/img/tiq8-Logo-white.png";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/Validation";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import moment from "moment";
import PasswordChecklist from "react-password-checklist";
import { GoDotFill } from "react-icons/go";
import { FaCheck } from "react-icons/fa";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Register() {
  const navigate = useNavigate("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [email, setEmail] = useState("");
  const [passsword, setPasssword] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [openOrgRegister, setOpenOrgRegister] = useState(false);
  const [openRegisterEmailInfo, setOpenRegisterEmailInfo] = useState(false);
  const [passVisibility, setPassVisibility] = useState(false);
  const [CPassVisibility, setCPassVisibility] = useState(false);
  const [registerBtn, setRegisterBtn] = useState(false);
  // errors
  const [fNameErr, setFNameErr] = useState("");
  const [lNameErr, setLNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [CPassErr, setCPassErr] = useState("");
  const [registerErr, setRegisterErr] = useState("");
  const [mobileerr, setMobileerr] = useState("");

  // org register
  const [orgUserFirstName, setOrgUserFirstName] = useState("");
  const [orgUserLastName, setOrgUserLastName] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [orgPhone, setOrgPhone] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [orgCountry, setOrgCountry] = useState("");
  const [orgState, setOrgState] = useState("");
  const [orgCity, setOrgCity] = useState("");
  const [orgZipcode, setOrgZipcode] = useState("");
  const [orgPassword, setOrgPassword] = useState("");
  const [confirmOrgPassword, setConfirmOrgPassword] = useState("");
  const [fNameErrorg, setFNameErrorg] = useState("");
  const [lNameErrorg, setLNameErrorg] = useState("");
  const [emailErrorg, setEmailErrorg] = useState("");
  const [passErrorg, setPassErrorg] = useState("");
  const [CPassErrorg, setCPassErrorg] = useState("");
  const [mobileerrorg, setMobileerrorg] = useState("");

  const [Country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [orgpassVisibility, setOrgpassVisibility] = useState(false);
  const [orgCPassVisibility, setOrgCPassVisibility] = useState(false);
  // language variable
  const [text_err_email, setText_err_email] = useState("Please Enter Email");
  const [text_err_pass, setText_err_pass] = useState("Please Enter Password");
  const [text_err_fname, setText_err_fname] = useState(
    "Please Enter First Name"
  );
  const [text_err_lname, setText_err_lname] = useState(
    "Please Enter Last Name"
  );
  const [text_err_valid_email, setText_err_valid_email] = useState(
    "Please Enter Valid Email"
  );
  const [text_err_strongPass, setText_err_strongPass] = useState(
    "Please Enter Strong Password"
  );
  const [text_err_confirmPass, setText_err_confirmPass] = useState(
    "Please Enter Confirm Password"
  );
  const [text_err_pass_match, setText_err_pass_match] = useState(
    "Password & Confirm Password Don't Match"
  );
  const [text_verify_head, setText_verify_head] = useState(
    "Please Verify Your Account"
  );
  const [text_okay, setText_okay] = useState("Okay");

  const [text_verify_msg, setText_verify_msg] = useState(
    "We have sent you a link on your registered email or phone no. to verify your Account."
  );
  const [text_verify_msg_span, setText_verify_msg_span] = useState(
    "Please check your Spam or Junk folder"
  );
  const [text_err_mobile, setText_err_mobile] = useState(
    "Please Enter Phone Number"
  );
  const [text_firstname, setText_firstname] = useState("First Name");
  const [text_lastname, setText_lastname] = useState("Last Name");
  const [text_confirmpass, setText_confirmpass] = useState("Confirm Password");
  const [text_password, setText_password] = useState("Password");
  const [text_email, setText_email] = useState("Email");
  const [text_phoneNumber, setText_phoneNumber] = useState("Phone Number");
  const [text_signup, setText_signup] = useState("Sign Up");
  const [text_ph_firstName, setText_ph_firstName] =
    useState("Enter First Name");
  const [text_ph_lastName, setText_ph_lastName] = useState("Enter Last Name");
  const [text_ph_email, setText_ph_email] = useState("Enter Email");
  const [text_ph_mobile, setText_ph_mobile] = useState("Enter Phone Number");
  const [text_ph_pass, setText_ph_pass] = useState("Enter Password");
  const [text_ph_confirmPass, setText_ph_confirmPass] = useState(
    "Enter Confirm Password"
  );
  const [text_reg_org, setText_reg_org] = useState("Register As Organization");
  const [text_reg_user, setText_reg_user] = useState("Register As User");
  const [text_signin, setText_signin] = useState("Sign In");
  const [text_have_account, setText_have_account] = useState(
    "Already have an account"
  );
  const [text_signuptotiq8, setText_signuptotiq8] = useState("Sign up to TiQ8");
  const [text_zipcode, setText_zipcode] = useState("Zipcode");
  const [text_ph_zipcode, setText_ph_zipcode] = useState("Enter zipcode");
  const [text_ph_city, setText_ph_city] = useState("Select City");
  const [text_ph_state, setText_ph_state] = useState("Select State");
  const [text_country, setText_country] = useState("Country");
  const [text_ph_country, setText_ph_country] = useState("Select Country");
  const [text_orgadd, setText_orgadd] = useState("Organization Address");
  const [text_orgadd1, setText_orgadd1] = useState(
    "Enter Organization Address"
  );
  const [text_orgname, setText_orgname] = useState("Organization Name");
  const [text_orgname1, setText_orgname1] = useState("Enter Organization Name");
  const [text_termsandcondition, setText_termsandcondition] =
    useState("Terms & Conditions");
  const [text_privacypolicy, setText_privacypolicy] =
    useState("Privacy Policy");

  const [text_agree1, setText_agree1] = useState(
    "By clicking 'Sign up', you agree to TiQ8"
  );
  const [text_agree2, setText_agree2] = useState("and have read the");
  const [login_title, setLogin_title] = useState(
    "The Easiest Way to Create Events and Sell More Tickets Online"
  );
  const [text_rightsReserved, setText_rightsReserved] = useState(
    "All rights reserved"
  );
  const [pass_minLength_Err, setPass_minLength_Err] = useState(
    "Password has at least 8 characters."
  );
  const [pass_specialChar_Err, setPass_specialChar_Err] = useState(
    "Password has special characters."
  );
  const [pass_number_Err, setPass_number_Err] = useState(
    "Password has a number."
  );
  const [pass_capital_Err, setPass_capital_Err] = useState(
    "Password has a capital letter."
  );

  const RegValidationHandler = () => {
    var isValidReg = true;

    if (!firstName) {
      isValidReg = false;
      setFNameErr(<>* {text_err_fname}!</>);
    }
    if (!lastName) {
      isValidReg = false;
      setLNameErr(<>* {text_err_lname}!</>);
    }
    if (email === "" && mobileNumber === "") {
      isValidReg = false;
      setMobileerr(<>* {text_err_mobile}!</>);
      setEmailErr(<>* {text_err_email}!</>);
    }
    if (mobileNumber !== "") {
      isValidReg = true;
      setEmailErr("");
    }
    if (email !== "" && EMAIL_REGEX.test(email) === false) {
      isValidReg = false;
      setEmailErr(<>* {text_err_valid_email}!</>);
      setMobileerr("");
    }

    if (EMAIL_REGEX.test(email) === true) {
      isValidReg = true;
      setEmailErr("");
      setMobileerr("");
    }
    if (!passsword) {
      isValidReg = false;
      setPassErr(<>* {text_err_pass}!</>);
    }
    if (passsword && !PASSWORD_REGEX.test(passsword) === true) {
      isValidReg = false;
      setPassErr(<>*{text_err_strongPass}</>);
    }
    if (!CPassword) {
      isValidReg = false;
      setCPassErr(<>* {text_err_confirmPass}!</>);
    }
    if (passsword !== CPassword) {
      isValidReg = false;
      setCPassErr(<>* {text_err_pass_match}!</>);
    }
    setRegisterBtn(false);
    return isValidReg;
  };

  const RegisterHandler = () => {
    if (RegValidationHandler()) {
      setRegisterBtn(true);
      console.log("in");

      var API_URL = API_DOMAIN + "user/createuser";

      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        email: email,
        password: passsword,
        role: "role",
        firstname: firstName,
        lastname: lastName,
        mobile: mobileNumber === "" ? "" : "+" + mobileNumber,
        country: "india",
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("reg res***", response);
          if (response.data.status === true) {
            setOpenRegisterEmailInfo(true);
            setRegisterBtn(false);
          }
        })
        .catch(function (error) {
          setRegisterBtn(false);
          console.log(error.response.data);
          setRegisterErr("* " + error?.response?.data?.message);
        })
        .then(function () {});
    }
  };
  const handleCloseRegisterEmailInfo = () => {
    setOpenRegisterEmailInfo(false);
    setTimeout(() => {
      navigate("/signin");
    }, [1000]);
  };

  const OrgValidationHandler = () => {
    var isValidOrg = true;

    if (!orgUserFirstName) {
      isValidOrg = false;
      setFNameErrorg(<>* {text_err_fname}!</>);
    }
    if (!orgUserLastName) {
      isValidOrg = false;
      setLNameErrorg(<>* {text_err_lname}!</>);
    }
    if (orgEmail === "" && orgPhone === "") {
      isValidOrg = false;
      setMobileerrorg(<>* {text_err_mobile}!</>);
      setEmailErrorg(<>* {text_err_email}!</>);
    }
    if (orgPhone !== "") {
      isValidOrg = true;
      setEmailErrorg("");
    }
    if (orgEmail !== "" && EMAIL_REGEX.test(orgEmail) === false) {
      isValidOrg = false;
      setEmailErrorg(<>* {text_err_valid_email}!</>);
      setMobileerrorg("");
    }
    if (EMAIL_REGEX.test(orgEmail) === true) {
      isValidOrg = true;
      setEmailErrorg("");
      setMobileerrorg("");
    }
    if (!orgPassword) {
      isValidOrg = false;
      setPassErrorg(<>* {text_err_pass}!</>);
    }

    if (!confirmOrgPassword) {
      isValidOrg = false;
      setCPassErrorg(<>* {text_err_confirmPass}!</>);
    }
    if (orgPassword !== confirmOrgPassword) {
      isValidOrg = false;
      setCPassErrorg(<>* {text_err_pass_match}!</>);
    }
    setRegisterBtn(false);
    return isValidOrg;
  };

  const OrgRegisterHandler = () => {
    if (OrgValidationHandler()) {
      setRegisterBtn(true);
      console.log("in");

      var API_URL = API_DOMAIN + "user/createuser";

      let headerConfig = {
        headers: {
          accept: "application/json",
        },
      };
      var data = {
        email: orgEmail,
        mobile: orgPhone === "" ? "" : "+" + orgPhone,
        country: orgCountry,
        orgaddress: orgAddress,
        orgstate: orgState,
        city: orgCity,
        zip: orgZipcode,
        firstname: orgUserFirstName,
        lastname: orgUserLastName,
        orgname: orgName,
        password: orgPassword,
      };
      axios
        .post(API_URL, data, headerConfig)
        .then(function (response) {
          console.log("reg res***", response);
          if (response.data.status === true) {
            setOpenRegisterEmailInfo(true);
            setRegisterBtn(false);
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          setRegisterBtn(false);
          setRegisterErr("* " + error?.response?.data?.message);
        })
        .then(function () {});
    }
  };

  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };
  const changeCVisibility = (e) => {
    e.preventDefault();
    setCPassVisibility(!CPassVisibility);
  };
  const OrgpassVisibility = (e) => {
    e.preventDefault();
    setOrgpassVisibility(!orgpassVisibility);
  };
  const OrgCPassCVisibility = (e) => {
    e.preventDefault();
    setOrgCPassVisibility(!orgCPassVisibility);
  };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      if (registerBtn) {
        return;
      }
      if (openOrgRegister === true) {
        OrgRegisterHandler();
      } else {
        RegisterHandler();
      }
    }
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_signin(
        xpath.select1("/resources/string[@name='text_signin']", doc).firstChild
          .data
      );
      setText_have_account(
        xpath.select1("/resources/string[@name='text_have_account']", doc)
          .firstChild.data
      );
      setText_zipcode(
        xpath.select1("/resources/string[@name='text_zipcode']", doc).firstChild
          .data
      );
      setText_ph_zipcode(
        xpath.select1("/resources/string[@name='text_ph_zipcode']", doc)
          .firstChild.data
      );
      setText_ph_city(
        xpath.select1("/resources/string[@name='text_ph_city']", doc).firstChild
          .data
      );

      setText_ph_country(
        xpath.select1("/resources/string[@name='text_ph_country']", doc)
          .firstChild.data
      );
      setText_ph_state(
        xpath.select1("/resources/string[@name='text_ph_state']", doc)
          .firstChild.data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );
      setText_orgname(
        xpath.select1("/resources/string[@name='text_orgname']", doc).firstChild
          .data
      );
      setText_orgname1(
        xpath.select1("/resources/string[@name='text_orgname1']", doc)
          .firstChild.data
      );
      setText_termsandcondition(
        xpath.select1("/resources/string[@name='text_termsandcondition']", doc)
          .firstChild.data
      );
      setText_privacypolicy(
        xpath.select1("/resources/string[@name='text_privacypolicy']", doc)
          .firstChild.data
      );
      setLogin_title(
        xpath.select1("/resources/string[@name='login_title']", doc).firstChild
          .data
      );
      setText_agree1(
        xpath.select1("/resources/string[@name='text_agree1']", doc).firstChild
          .data
      );
      setText_agree2(
        xpath.select1("/resources/string[@name='text_agree2']", doc).firstChild
          .data
      );
      setText_rightsReserved(
        xpath.select1("/resources/string[@name='text_rightsReserved']", doc)
          .firstChild.data
      );
      setPass_minLength_Err(
        xpath.select1("/resources/string[@name='pass_minLength_Err']", doc)
          .firstChild.data
      );
      setPass_specialChar_Err(
        xpath.select1("/resources/string[@name='pass_specialChar_Err']", doc)
          .firstChild.data
      );
      setPass_number_Err(
        xpath.select1("/resources/string[@name='pass_number_Err']", doc)
          .firstChild.data
      );
      setPass_capital_Err(
        xpath.select1("/resources/string[@name='pass_capital_Err']", doc)
          .firstChild.data
      );
      setText_reg_user(
        xpath.select1("/resources/string[@name='text_reg_user']", doc)
          .firstChild.data
      );
      setText_err_email(
        xpath.select1("/resources/string[@name='text_err_email']", doc)
          .firstChild.data
      );
      setText_err_pass(
        xpath.select1("/resources/string[@name='text_err_pass']", doc)
          .firstChild.data
      );
      setText_err_fname(
        xpath.select1("/resources/string[@name='text_err_fname']", doc)
          .firstChild.data
      );
      setText_err_lname(
        xpath.select1("/resources/string[@name='text_err_lname']", doc)
          .firstChild.data
      );
      setText_err_valid_email(
        xpath.select1("/resources/string[@name='text_err_valid_email']", doc)
          .firstChild.data
      );
      setText_err_strongPass(
        xpath.select1("/resources/string[@name='text_err_strongPass']", doc)
          .firstChild.data
      );
      setText_err_confirmPass(
        xpath.select1("/resources/string[@name='text_err_confirmPass']", doc)
          .firstChild.data
      );
      setText_err_pass_match(
        xpath.select1("/resources/string[@name='text_err_pass_match']", doc)
          .firstChild.data
      );
      setText_verify_head(
        xpath.select1("/resources/string[@name='text_verify_head']", doc)
          .firstChild.data
      );
      setText_okay(
        xpath.select1("/resources/string[@name='text_okay']", doc).firstChild
          .data
      );
      setText_verify_msg(
        xpath.select1("/resources/string[@name='text_verify_msg']", doc)
          .firstChild.data
      );
      setText_verify_msg_span(
        xpath.select1("/resources/string[@name='text_verify_msg_span']", doc)
          .firstChild.data
      );
      setText_firstname(
        xpath.select1("/resources/string[@name='text_firstname']", doc)
          .firstChild.data
      );
      setText_lastname(
        xpath.select1("/resources/string[@name='text_lastname']", doc)
          .firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_phoneNumber(
        xpath.select1("/resources/string[@name='text_phoneNumber']", doc)
          .firstChild.data
      );
      setText_password(
        xpath.select1("/resources/string[@name='text_password']", doc)
          .firstChild.data
      );
      setText_confirmpass(
        xpath.select1("/resources/string[@name='text_confirmpass']", doc)
          .firstChild.data
      );
      setText_signup(
        xpath.select1("/resources/string[@name='text_signup']", doc).firstChild
          .data
      );
      setText_ph_firstName(
        xpath.select1("/resources/string[@name='text_ph_firstName']", doc)
          .firstChild.data
      );
      setText_ph_lastName(
        xpath.select1("/resources/string[@name='text_ph_lastName']", doc)
          .firstChild.data
      );
      setText_ph_email(
        xpath.select1("/resources/string[@name='text_ph_email']", doc)
          .firstChild.data
      );
      setText_ph_mobile(
        xpath.select1("/resources/string[@name='text_ph_mobile']", doc)
          .firstChild.data
      );
      setText_ph_pass(
        xpath.select1("/resources/string[@name='text_ph_pass']", doc).firstChild
          .data
      );
      setText_ph_confirmPass(
        xpath.select1("/resources/string[@name='text_ph_confirmPass']", doc)
          .firstChild.data
      );
      setText_reg_org(
        xpath.select1("/resources/string[@name='text_reg_org']", doc).firstChild
          .data
      );

      setText_signuptotiq8(
        xpath.select1("/resources/string[@name='text_signuptotiq8']", doc)
          .firstChild.data
      );

      setText_err_mobile(
        xpath.select1("/resources/string[@name='text_err_mobile']", doc)
          .firstChild.data
      );

      setText_orgadd(
        xpath.select1("/resources/string[@name='text_orgadd']", doc).firstChild
          .data
      );
      setText_orgadd1(
        xpath.select1("/resources/string[@name='text_orgadd1']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <div className="form-wrapper" id="otherPages" onKeyPress={keyHandler}>
        <div className="app-form">
          <div className="app-form-sidebar">
            <Link to="/">
              <div className="sidebar-sign-logo">
                <img src={mainlogo} alt="" className="side-logo-outerPage" />
              </div>
            </Link>
            <div className="sign_sidebar_text">
              <h1>{login_title}</h1>
            </div>
          </div>
          <div className="app-form-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="app-top-items">
                    <Link to="/">
                      <div className="sign-logo" id="logo">
                        <img src={mainlogo} alt="" />
                        <img className="logo-inverse" src={mainlogo} alt="" />
                      </div>
                    </Link>
                    <div className="app-top-right-link">
                      {text_have_account} ?
                      <Link className="sidebar-register-link" to="/signin">
                        {text_signin}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-7">
                  <div className="registration">
                    <div>
                      <h2 className="registration-title text-center">
                        {text_signuptotiq8}
                      </h2>
                      <div className="agree-text">
                        <Form>
                          <div className="mt-3 register-radio">
                            <Form.Check
                              // inline
                              label={text_reg_user}
                              name="group1"
                              type="radio"
                              id="inline-radio-2"
                              style={{ fontSize: "medium" }}
                              value="Atom Storage Network"
                              checked={openOrgRegister === false}
                              onChange={() => [
                                setOpenOrgRegister(!openOrgRegister),
                                setEmail(""),
                                setPasssword(""),
                                setCPassword(""),
                                setMobileNumber(""),
                                setFirstName(""),
                                setLastName(""),
                                setFNameErr(""),
                                setLNameErr(""),
                                setEmailErr(""),
                                setPassErr(""),
                                setCPassErr(""),
                                setMobileerr(""),
                                setRegisterErr(""),
                                setRegisterBtn(false),
                              ]}
                            />
                            <Form.Check
                              // inline
                              label={text_reg_org}
                              name="group1"
                              type="radio"
                              id="inline-radio-1"
                              style={{ fontSize: "medium" }}
                              checked={openOrgRegister === true}
                              onChange={() => [
                                setOpenOrgRegister(!openOrgRegister),
                                setOrgUserFirstName(""),
                                setOrgUserLastName(""),
                                setOrgEmail(""),
                                setOrgPhone(""),
                                setOrgName(""),
                                setOrgAddress(""),
                                setOrgCountry(""),
                                setOrgState(""),
                                setOrgCity(""),
                                setOrgZipcode(""),
                                setOrgPassword(""),
                                setConfirmOrgPassword(""),
                                setFNameErrorg(""),
                                setLNameErrorg(""),
                                setEmailErrorg(""),
                                setMobileerrorg(""),
                                setPassErrorg(""),
                                setCPassErrorg(""),
                                setRegisterErr(""),
                                setRegisterBtn(false),
                              ]}
                            />
                          </div>
                        </Form>
                      </div>
                      {openOrgRegister === false ? (
                        <>
                          <div className="row ">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_firstname}
                                  <span className="starsty">*</span>
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_ph_firstName}
                                  onChange={(e) => [
                                    setFirstName(e.target.value),
                                    setFNameErr(""),
                                  ]}
                                />
                              </div>
                              <span className="starsty">{fNameErr}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_lastname}
                                  <span className="starsty">*</span>
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_ph_lastName}
                                  onChange={(e) => [
                                    setLastName(e.target.value),
                                    setLNameErr(""),
                                  ]}
                                />
                              </div>
                              <span className="starsty">{lNameErr}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_email}
                                  <span className="starsty">*</span>
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="email"
                                  placeholder={text_ph_email}
                                  onChange={(e) => [
                                    setEmail(e.target.value),
                                    setEmailErr(""),
                                    setMobileerr(""),
                                    setRegisterErr(""),
                                  ]}
                                />
                              </div>
                              <span className="starsty">{emailErr}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_phoneNumber}
                                </label>
                                <PhoneInput
                                  onChange={(e) => [
                                    setMobileNumber(e),
                                    setEmailErr(""),
                                    setMobileerr(""),
                                    setRegisterErr(""),
                                  ]}
                                  inputclassName="Register-input"
                                  placeholder={text_ph_mobile}
                                />
                              </div>
                              <span className="starsty">{mobileerr}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <div className="field-password">
                                  <label className="form-label">
                                    {text_password}
                                    <span className="starsty">*</span>
                                  </label>
                                </div>
                                <div className="loc-group position-relative">
                                  <input
                                    className="form-control h_50"
                                    type={passVisibility ? "text" : "password"}
                                    placeholder={text_ph_pass}
                                    onChange={(e) => [
                                      setPasssword(e.target.value),
                                      setPassErr(""),
                                    ]}
                                  />
                                  <span className="pass-show-eye">
                                    <button
                                      className="reg-eye"
                                      onClick={(e) => changeVisibility(e)}
                                    >
                                      {passVisibility === true ? (
                                        <BsEyeFill className="showpassnight" />
                                      ) : (
                                        <BsEyeSlashFill className="showpassnight" />
                                      )}
                                    </button>
                                  </span>
                                </div>
                                <span className="starsty">{passErr}</span>

                                <PasswordChecklist
                                  rules={[
                                    "minLength",
                                    "specialChar",
                                    "number",
                                    "capital",
                                  ]}
                                  minLength={8}
                                  value={passsword}
                                  messages={{
                                    minLength: <> {pass_minLength_Err}</>,
                                    specialChar: <>{pass_specialChar_Err}</>,
                                    number: <>{pass_number_Err}</>,
                                    capital: <>{pass_capital_Err}</>,
                                  }}
                                  iconComponents={{
                                    ValidIcon: (
                                      <FaCheck
                                        style={{
                                          color: "green",
                                          marginRight: "7px",
                                        }}
                                      />
                                    ),
                                    InvalidIcon: (
                                      <GoDotFill
                                        style={{ marginRight: "7px" }}
                                      />
                                    ),
                                  }}
                                  iconSize={30}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <div className="field-password">
                                  <label className="form-label">
                                    {text_confirmpass}
                                    <span className="starsty">*</span>
                                  </label>
                                </div>
                                <div className="loc-group position-relative">
                                  <input
                                    className="form-control h_50"
                                    type={CPassVisibility ? "text" : "password"}
                                    placeholder={text_ph_confirmPass}
                                    onChange={(e) => [
                                      setCPassword(e.target.value),
                                      setCPassErr(""),
                                    ]}
                                  />
                                  <span className="pass-show-eye">
                                    <button
                                      className="reg-eye"
                                      onClick={(e) => changeCVisibility(e)}
                                    >
                                      {CPassVisibility === true ? (
                                        <BsEyeFill className="showpassnight" />
                                      ) : (
                                        <BsEyeSlashFill className="showpassnight" />
                                      )}
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <span className="starsty">{CPassErr}</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {openOrgRegister === true ? (
                        <>
                          <div className="row mt-3">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_firstname}
                                  <span className="starsty">*</span>
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_ph_firstName}
                                  onChange={(e) => [
                                    setOrgUserFirstName(e.target.value),
                                    setFNameErrorg(""),
                                  ]}
                                />
                              </div>
                              <span className="starsty">{fNameErrorg}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_lastname}
                                  <span className="starsty">*</span>
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_ph_lastName}
                                  onChange={(e) => [
                                    setOrgUserLastName(e.target.value),
                                    setLNameErrorg(""),
                                  ]}
                                />
                              </div>
                              <span className="starsty">{lNameErrorg}</span>
                            </div>

                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_orgname}
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_orgname1}
                                  onChange={(e) => [setOrgName(e.target.value)]}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_country}
                                </label>
                                <CountrySelect
                                  onChange={(e) => [
                                    setCountry(e.id),
                                    setOrgCountry(e.name),
                                  ]}
                                  showFlag={false}
                                  placeHolder={text_ph_country}
                                  className="header-signup-inputs country-org-container form-control h_50"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_email}
                                  <span className="starsty">*</span>
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="email"
                                  placeholder={text_ph_email}
                                  onChange={(e) => [
                                    setOrgEmail(e.target.value),
                                    setEmailErrorg(""),
                                    setMobileerrorg(""),
                                    setRegisterErr(""),
                                  ]}
                                />
                              </div>
                              <span className="starsty">{emailErrorg}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_phoneNumber}
                                </label>
                                <PhoneInput
                                  country={"us"}
                                  onChange={(e) => [
                                    setOrgPhone(e),
                                    setEmailErrorg(""),
                                    setMobileerrorg(""),
                                    setRegisterErr(""),
                                  ]}
                                  inputclassName="Register-input"
                                  placeholder={text_ph_mobile}
                                  value={orgPhone}
                                />
                              </div>
                              <span className="starsty">{mobileerrorg}</span>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_orgadd}
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_orgadd1}
                                  onChange={(e) => [
                                    setOrgAddress(e.target.value),
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_ph_state}
                                </label>
                                <StateSelect
                                  countryid={Country}
                                  onChange={(e) => [
                                    setState(e.id),
                                    setOrgState(e.name),
                                  ]}
                                  placeHolder={text_ph_state}
                                  className="header-signup-inputs"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_ph_city}
                                </label>
                                <CitySelect
                                  countryid={Country}
                                  stateid={state}
                                  onChange={(e) => [
                                    console.log(e.name),
                                    setOrgCity(e.name),
                                  ]}
                                  placeHolder={text_ph_city}
                                  className="header-signup-inputs"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <label className="form-label">
                                  {text_zipcode}
                                </label>
                                <input
                                  className="form-control h_50"
                                  type="text"
                                  placeholder={text_ph_zipcode}
                                  onChange={(e) => [
                                    setOrgZipcode(e.target.value),
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <div className="field-password">
                                  <label className="form-label">
                                    {text_password}
                                    <span className="starsty">*</span>
                                  </label>
                                </div>
                                <div className="loc-group position-relative">
                                  <input
                                    className="form-control h_50"
                                    type={
                                      orgpassVisibility ? "text" : "password"
                                    }
                                    placeholder={text_ph_pass}
                                    onChange={(e) => [
                                      setOrgPassword(e.target.value),
                                      setPassErrorg(""),
                                    ]}
                                  />
                                  <span className="pass-show-eye">
                                    <button
                                      className="reg-eye"
                                      onClick={(e) => OrgpassVisibility(e)}
                                    >
                                      {orgpassVisibility === true ? (
                                        <BsEyeFill className="showpassnight" />
                                      ) : (
                                        <BsEyeSlashFill className="showpassnight" />
                                      )}
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <span className="starsty">{passErrorg}</span>

                              <PasswordChecklist
                                rules={[
                                  "minLength",
                                  "specialChar",
                                  "number",
                                  "capital",
                                ]}
                                minLength={8}
                                value={orgPassword}
                                messages={{
                                  minLength: <> {pass_minLength_Err}</>,
                                  specialChar: <>{pass_specialChar_Err}</>,
                                  number: <>{pass_number_Err}</>,
                                  capital: <>{pass_capital_Err}</>,
                                }}
                                iconComponents={{
                                  ValidIcon: (
                                    <FaCheck
                                      style={{
                                        color: "green",
                                        marginRight: "7px",
                                      }}
                                    />
                                  ),
                                  InvalidIcon: (
                                    <GoDotFill style={{ marginRight: "7px" }} />
                                  ),
                                }}
                              />
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group mt-4">
                                <div className="field-password">
                                  <label className="form-label">
                                    {text_confirmpass}
                                    <span className="starsty">*</span>
                                  </label>
                                </div>
                                <div className="loc-group position-relative">
                                  <input
                                    className="form-control h_50"
                                    type={
                                      orgCPassVisibility ? "text" : "password"
                                    }
                                    placeholder={text_ph_confirmPass}
                                    onChange={(e) => [
                                      setConfirmOrgPassword(e.target.value),
                                      setCPassErrorg(""),
                                    ]}
                                  />
                                  <span className="pass-show-eye">
                                    <button
                                      className="reg-eye"
                                      onClick={(e) => OrgCPassCVisibility(e)}
                                    >
                                      {orgCPassVisibility === true ? (
                                        <BsEyeFill className="showpassnight" />
                                      ) : (
                                        <BsEyeSlashFill className="showpassnight" />
                                      )}
                                    </button>
                                  </span>
                                </div>
                              </div>
                              <span className="starsty">{CPassErrorg}</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <button
                          className="main-btn btn-hover w-100 mt-4"
                          onClick={
                            openOrgRegister === true
                              ? OrgRegisterHandler
                              : RegisterHandler
                          }
                        >
                          {text_signup}
                        </button>
                      </div>
                      <b>
                        {" "}
                        <center className="mt-2">
                          <span className="regErr-p mt-2">{registerErr}</span>
                        </center>
                      </b>
                    </div>
                    <div className="agree-text">
                      {text_agree1}{" "}
                      <Link to="/terms-conditions">
                        {text_termsandcondition}
                      </Link>{" "}
                      {text_agree2}{" "}
                      <Link to="/privacy-policy">{text_privacypolicy}</Link>.
                    </div>
                    <div className="new-sign-link">
                      {text_have_account}?
                      <Link className="signup-link" to="/signin">
                        {text_signin}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-footer">
              © {moment(new Date()).format("YYYY")}, TiQ8. {text_rightsReserved}
              .
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openRegisterEmailInfo}
        onClose={() => setOpenRegisterEmailInfo(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">{text_verify_head}</h3>
            <h5 className="text-center">{text_verify_msg}</h5>
            <h5 className="text-center">{text_verify_msg_span}</h5>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <>
          <div className="d-flex justify-content-center mb-3">
            <button
              className="main-btn "
              onClick={handleCloseRegisterEmailInfo}
            >
              {text_okay}
            </button>
          </div>
        </>
      </Dialog>
      <ToastContainer />
    </>
  );
}

export default Register;
