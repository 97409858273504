import React, { useState, useEffect } from "react";
import "../TermsConditions/TermsConditions.css";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function TermsConditions() {
  const [text_home, setText_home] = useState("Home");
  const [text_termsandcondition, setText_termsandcondition] =
    useState("Terms & Conditions");
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_termsandcondition(
        xpath.select1("/resources/string[@name='text_termsandcondition']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div
        className="Create-event-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_termsandcondition}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container termsbox mt-4 mb-4">
          <div className="termsheadbox p-3">
            <h3 className="text-center privacy-text mt-4 text-white">
              {text_termsandcondition}
            </h3>
            <h4 className="text-white">Last Updated: 15 December 2023</h4>
          </div>
          <p className="mt-3">tiq8.com</p>
          <p>
            Welcome to tiq8.com, a platform for ticketing and event creation.
            These Terms and Conditions ("Terms") govern your use of our website
            and services. By accessing or using our website, you agree to comply
            with and be bound by these Terms. If you do not agree with these
            Terms, please do not use our website.
          </p>
          <p>
            We provide the tiq8.com Websites to you subject to these Terms of
            Use (“Terms”), which may be updated by us from time to time. By
            accessing and using the Websites, you accept and agree to be bound
            by these Terms, tiq8.com Privacy Policy found here and tiq8.com
            Cookie Policy found here. If you do not agree to these Terms, you
            should not access or use the Websites.{" "}
          </p>
          <p>
            The practices and policies, including how we protect, collect, and
            use data, text, or other submitted to and stored within the Services
            by You (“Service Data”) are detailed in and governed by our
            Agreement, available here, or such other applicable agreement
            between you and any member of the tiq8.com relating to your access
            to and use of such Services (“Service Agreement”).
          </p>
          <p>
            Changes to Terms. These Terms, or any part thereof, may be modified
            by us, including the addition or removal of terms at any time, and
            such modifications, additions or deletions will be effective
            immediately upon posting. Your use of the Websites after such
            posting shall be deemed to constitute acceptance by you of such
            modifications, additions or deletions.
          </p>
          <p>
            2. Changes to Websites. We may change or discontinue any aspect,
            service or feature of the Websites at any time, including, but not
            limited to, content, availability, and equipment needed for access
            or use.
          </p>
          <h4 className="privacy-body-text ">Account Registration</h4>
          <p>
            You agree to provide accurate, current, and complete information
            during the registration process.
          </p>
          <p>
            You are responsible for maintaining the confidentiality of your
            account and password and for restricting access to your account. You
            agree to accept responsibility for all activities that occur under
            your account.
          </p>
          <p>
            You should also read our Privacy Policy which sets out how we
            collect and use your personal information.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Website Name if you do not agree
            to take all of the terms and conditions stated on this page.
          </p>
          <h4 className="privacy-body-text ">
            USING{" "}
            <Link to={"https://www.tiq8.com/"} target="blank">
              www.tiq8.com.
            </Link>{" "}
            AND IT’S SERVICES{" "}
          </h4>
          <p>
            This website may contain links to third party/websites that are not
            owned, controlled, operated and managed by tiq8.com. Which have no
            control over and assumes no responsibility for, the content, privacy
            policies, or practices of any third-party/websites. Besides,
            tiq8.com will not and cannot censor or edit the content of any
            third-party/website. By using this website, you expressly relieve
            tiq8.com from any liability arising from your use of any third-party
            contents/website. Accordingly, we encourage you to be aware when you
            leave ‘www.tiq8.com’/website, you must read the terms and conditions
            and privacy policy of the website(s) that you may visit later.
          </p>
          <h4 className="privacy-body-text ">ASSIGNMENT</h4>
          <p>
            These Terms of Service, and any rights and licenses granted
            hereunder, may not be transferred or assigned by you but may be
            assigned by www.tiq8.com without any restriction.
          </p>
          <h4 className="privacy-body-text ">COOKIE POLICY </h4>
          <p>
            There may be times when www.tiq8.com may need information from you.
            Additionally, our websites may require you to complete a
            registration form or seek some information from you. Our goal in
            collecting such information is to offer you more personalized
            browsing experience. When you let us have your preferences, we will
            be able to deliver or allow you to access the most relevant
            information that meets your end. We will only keep your information
            for as long as law either requires us to or as is relevant for the
            purposes for which it was collected. To extend this personalized
            experience, we may save information on your system in the form of
            cookies. We will not use or share any information collected at this
            Website in any manner except as permitted at law. The detailed
            cookie policy can be accessed here.
          </p>
          <h4 className="privacy-body-text ">
            PURPOSE FOR THESE TERMS AND CONDITIONS{" "}
          </h4>
          <p>
            These Terms and Conditions together read with Registration Form
            constitute the entire agreement. The Participant acknowledges and
            understands that he/she has full knowledge of these terms and
            conditions and is bound with them in every aspect and thereby has
            not relied on and shall have no remedy in respect of any statement,
            terms, conditions, representation, warranty, understanding, promise
            or assurance other than as expressly set out under this agreement,
            whether wilfully or negligently made by any person.
          </p>
          <p>
            Should you have any questions, please do not hesitate to contact us:{" "}
            <Link to="mailto:support@tiq8.com">support@tiq8.com</Link>
          </p>
          <p>Good luck with your collaborative initiatives! </p>
          <p>Thanking You, </p>
          <p>Team – www.tiq8.com</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsConditions;
